export default {
  "error_first_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ime"])},
  "error_last_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prezime"])},
  "error_telephone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Broj telefona"])},
  "error_email_address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email adresa"])},
  "error_address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ulica i broj"])},
  "office_hours": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Radimo svakim danom osim nedjelje od 08:00 do 22:30."])},
  "order": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Naruči"])},
  "enter_details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Molimo unesite slijedeće podatke"])},
  "you_have_selected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Odabrali ste"])},
  "delivery_details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Detalji preuzimanja"])},
  "order_confirmation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Slanje narudžbe"])},
  "whole_wheat_flour": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Integralno brašno"])},
  "continue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nastavi"])},
  "first_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ime"])},
  "last_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prezime"])},
  "telephone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Broj telefona"])},
  "email_address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email adresa"])},
  "address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ulica i broj"])},
  "city": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grad"])},
  "additional_information": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dodatne informacije"])},
  "close": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zatvori"])},
  "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zatvori"])},
  "type_of_delivery": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vrsta preuzimanja"])},
  "delivery_type_0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Putem kućne dostave na Vašu adresu po cijeni od 2 KM"])},
  "delivery_type_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Putem ličnog preuzimanja na našoj adresi bez dodatnih troškova"])},
  "pickup_text": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Svoju narudžbu možete preuzeti lično u prostorijama UR PIZZA TRKAČICA koje se nalze u ulici Nasiba Malkića broj 20. Za detaljni prikaz lokacije na Goggle Maps, molimo klinite <a href='", _interpolate(_named("link")), "' target='_new'>ovdje.</a>"])},
  "price_inclusive_of_vat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["* Sve izražne cijene uključuju PDV (Porez na Dodatnu Vrijednost)"])},
  "order_sending_in_progress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Slanje narudžbe u toku"])},
  "error_encountered": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Došlo je do tehničke greške u snimanju narudžbe."])},
  "please_contact": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Molimo da nas kontaktirate na"])},
  "inconvenience": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Žao nam je zbog neugodnosti i nadamo se Vašem razumjevanju."])},
  "thank_you_for_your_order": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hvala Vam na povjerenju!"])},
  "order_success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uspješno smo zaprimili Vašu narudžbu."])},
  "stuffed_crust_short": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PS"])},
  "footer_text": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["&copy; UR Pizza Trkačica 2007 - ", _interpolate(_named("year")), ". Sva prava zadržana. Reprodukcija u cijelosti ili dijelom, u bilo kakvoj formi ili mediju, bez izričite pismene dozvole od strane UR Pizza Trkačica je strogo zabranjena.<br />UR Pizza Trkačica, Nesiba Malkića 20, 75000 Tuzla, Bosna i Hercegovina"])},
  "send": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pošalji narudžbu"])},
  "starting_from": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["već od ", _interpolate(_named("price")), " KM"])}
}