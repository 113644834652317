import { createApp } from 'vue'
import { createVuetify } from 'vuetify'
import { createVueI18nAdapter } from 'vuetify/locale/adapters/vue-i18n'
import { useI18n } from 'vue-i18n'
import App from './trkacica.vue'

import 'vuetify/styles'
import * as components from 'vuetify/components'
import * as directives from 'vuetify/directives'
import { aliases, mdi } from 'vuetify/iconsets/mdi'
import "@mdi/font/css/materialdesignicons.css";
import moment from 'moment';


import axios from "axios";
axios.defaults.baseURL = process.env.VUE_APP_TRKACICA_API;
import i18n from './i18n'
axios.defaults.timeout = 0; // 10 minutes
i18n.silentTranslationWarn = true;

const vuetify = createVuetify({
    locale: {
        adapter: createVueI18nAdapter({ i18n, useI18n }),
    },
    global: {
        ripple: false,
    },
    theme: {
        defaultTheme: 'dark'
    },
    icons: {
        defaultSet: 'mdi',
        aliases,
        sets: {
            mdi
        },
    },
    components,
    directives,
    aliases,
    sets: {
        mdi,
    }
})


createApp(App).use(i18n).use(vuetify, axios, moment).mount('#app')