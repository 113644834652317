export default {
  "error_first_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Firstname"])},
  "error_last_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lastname"])},
  "error_telephone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Telephone"])},
  "error_email_address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email address"])},
  "error_address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Street and number"])},
  "office_hours": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We are open every day (except Sundays) from 08:00 to 22:30."])},
  "order": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Order"])},
  "enter_details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter the following details"])},
  "you_have_selected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You have selected"])},
  "delivery_details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delivery details"])},
  "order_confirmation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Order confirmation"])},
  "whole_wheat_flour": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Whole wheat flour"])},
  "continue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Continue"])},
  "first_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["First name"])},
  "last_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Last name"])},
  "telephone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Telephone"])},
  "email_address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email address"])},
  "address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Street name and number"])},
  "city": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["City"])},
  "additional_information": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Additional information"])},
  "close": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancel"])},
  "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancel"])},
  "type_of_delivery": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["How will the products be delivered?"])},
  "delivery_type_0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Via home delivery at an additional cost of 2KM"])},
  "delivery_type_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pickup from our address at no additional cost"])},
  "pickup_text": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["You can pickup your order in person from our pizza shop located at Nasiba Malkića street number 20. For a detailed view of our pickup location on Goggle Maps, please click <a href='", _interpolate(_named("link")), "' target='_new'>here.</a>"])},
  "price_inclusive_of_vat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["* All prices shown are inclusive of VAT (Value Added Tax)"])},
  "order_sending_in_progress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Order sending in progress"])},
  "error_encountered": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We are experiencing technical difficulties."])},
  "please_contact": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please contact us on"])},
  "inconvenience": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We are sorry for the inconvenience."])},
  "thank_you_for_your_order": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thank you for ordering!"])},
  "order_success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We have successfully received your order."])},
  "stuffed_crust_short": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CSC"])},
  "footer_text": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["&copy; UR Pizza Trkačica 2007 - ", _interpolate(_named("year")), ". All rights reserved. Reproduction, in whole or in part, in any form or medium, without the explicit written permission from UR Pizza Trkacica, is strictly prohibited.<br />UR Pizza Trkačica, Nesiba Malkića 20, 75000 Tuzla, Bosna i Hercegovina"])},
  "send": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Send order"])},
  "starting_from": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["starting from ", _interpolate(_named("price")), " KM"])}
}